<template>
  <div class="sound-wave">
    <div class="sound-wave-item sound-wave-1"></div>
    <div class="sound-wave-item sound-wave-2"></div>
    <div class="sound-wave-item sound-wave-3"></div>
    <div class="sound-wave-item sound-wave-4"></div>
    <div class="sound-wave-item sound-wave-5"></div>
    <div class="sound-wave-item sound-wave-6"></div>
    <div class="sound-wave-item sound-wave-5"></div>
    <div class="sound-wave-item sound-wave-4"></div>
    <div class="sound-wave-item sound-wave-3"></div>
    <div class="sound-wave-item sound-wave-2"></div>
    <div class="sound-wave-item sound-wave-1"></div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="less" scoped>
.sound-wave {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 75px;
}

.sound-wave-item {
  width: 4px;
  border-radius: 100px;
  background: #000;
  margin: 0 2px;
}

@keyframes wave-animation-1 {
  0%, 100% {
    height: 8px;
  }
  /* 最中间的条的动画周期 */
  50% {
    height: 16px;
  }
}

@keyframes wave-animation-2 {
  0%, 100% {
    height: 10px;
  }
  /* 两侧的条的动画周期 */
  50% {
    height: 14px;
  }
}

@keyframes wave-animation-3 {
  0%, 100% {
    height: 12px;
  }
  /* 两侧的条的动画周期 */
  50% {
    height: 18px;
  }
}

.sound-wave-1 {
  animation: wave-animation-1 1s infinite alternate ease-in-out;
  background: #fef7f6;
}

/* 最中间的条 */
.sound-wave-2 {
  animation: wave-animation-2 0.8s infinite alternate ease-in-out;
  background: #f8d7cf;
}

/* 两侧的条 */
.sound-wave-3 {
  animation: wave-animation-3 1.1s infinite alternate ease-in-out;
  background: #f2a698;
}

/* 两侧的条 */
.sound-wave-4 {
  animation: wave-animation-2 0.7s infinite alternate ease-in-out;
  background: #ee836e;
}

/* 两侧的条 */
.sound-wave-5 {
  animation: wave-animation-1 0.9s infinite alternate ease-in-out;
  background: #ed6d4e;
}

/* 最中间的条 */
.sound-wave-6 {
  animation: wave-animation-3 1s infinite alternate ease-in-out;
  background: #ec5b3c;
}

/* 两侧的条 */
</style>
