import {v2Request} from "./v2Request";

export const chatApi = {

  resetRefPlans(chatId) {
    return v2Request.post('/ai/chat/resetRefPlans', {id: chatId})
  },


  ask(data) {
    return v2Request.post('/ai/chat/ask', data)
  },

  stop(data) {
    return v2Request.post('/ai/chat/ask', data)
  },


  prepare(form) {
    return v2Request.post(`/ai/chat/prepare`, form);
  },


  findById(id) {
    id = id + ''
    return v2Request.post(`/ai/chat/findById`, {id});
  },


  findRefPlans(id) {
    id = id + ''
    return v2Request.post(`/ai/chat/findRefPlans`, {id});
  },


  findRefQuestions(id) {
    id = id + ''
    return v2Request.post(`/ai/chat/findRefQuestions`, {id});
  },


  findSummary(id) {
    id = id + ''
    return v2Request.post(`/ai/chat/findSummary`, {id});
  },


  resetGenSummaryStatus(id) {
    id = id + ''
    return v2Request.post(`/ai/chat/resetGenSummaryStatus`, {id});
  },

  toggleHasLike(chatRecordId) {
    return v2Request.post(`/ai/chat/toggleHasLike`, {chatRecordId: chatRecordId + ''});
  },


  toggleHasDislike(chatRecordId) {
    return v2Request.post(`/ai/chat/toggleHasDislike`, {chatRecordId: chatRecordId + ''});
  }

}
