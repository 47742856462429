<template>
  <div class="ai-search-input-container" v-show="!showClose || visible" :class="{'voice-inputting': voiceInputting}">

    <div class="close-btn" v-if="showClose" @click="visible=false">
      <van-icon name="cross"/>
    </div>

    <!--    会员提醒-->
    <div class="limit-badge">
      <template v-if="userVip.vipRightId === '3'">
        <img src="https://cdn.001ppt.cn/pc2/static/imgs/jpg/vip-not-join.png" alt=""/>
      </template>
      <template v-else>
        <img :src="getVipIcon(userVip.vipRightId)" alt=""/>
      </template>
      限时免费
    </div>

    <!--  吉祥物-->
    <img class="ai-wave-icon" src="https://cdn.001ppt.cn/h5/ai/ai-wave.png" alt=""/>


    <!--    主输入区-->
    <div class="ai-search-input">
      <div class="voice-input-area" v-if="voiceInputting">
        <div class="voice-recognize">{{ recognizeSentences.join('') || '...' }}</div>
        <div class="voice-cancel-tip">松手查找，上移取消</div>
      </div>

      <div class="ai-search-input-main" ref="inputMain" @contextmenu="onContextMenu">
        <img src="https://cdn.001ppt.cn/h5/ai/text-input.svg" alt="" class="left-icon" @click="switchInputMode"
             v-if="inputMode==='voice'"/>
        <img src="https://cdn.001ppt.cn/h5/ai/voice-input.svg" alt="" class="left-icon" @click="switchInputMode"
             v-else-if="inputMode==='text'"/>
        <div v-if="inputMode==='text'">
          <input @keydown.enter="ask" type="text" class="ai-input-text" placeholder="下载资料 一问就有"
                 :disabled="loading"
                 v-model="question"/>
        </div>

        <div class="ai-input-voice" v-else-if="inputMode==='voice'" v-longpress="startVoiceInput"
             @contextmenu="onContextMenu"
             v-longpress-end="stopVoiceInput">
          <template v-if="loading">灵感思考中
            <van-loading type="spinner" size="12px" color="#ee4a50" style="margin-left: 2px"/>
          </template>
          <template v-else-if="voiceInputting">
            <sound-wave/>
          </template>
          <template v-else>长按说话 一问就有</template>
        </div>


        <template v-if="inputMode === 'text'">
          <div v-if="loading" class="stop-btn" @click="$emit('stop')"></div>
          <img src="https://cdn.001ppt.cn/h5/ai/ai-search-confirm.png" alt="" class="confirm-icon" @click="ask"
               v-else/>
        </template>

      </div>

    </div>

  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {hasRecordPermission, requestRecordPermission, sleep} from "../../config/util";
import WebAudioSpeechRecognizer from "../../../sdk/tencent-speech/app/webaudiospeechrecognizer";
import {tencentV2Api} from "../../api/v2/tencentV2Api";
import SoundWave from "./soundWave.vue";

export default {
  props: ['showClose', 'loading'],
  components: {SoundWave},
  computed: {
    ...mapGetters(['userVip']),
  },

  data() {
    return {
      visible: true,
      voiceInputting: false,
      inputMode: 'voice',
      cancelVoiceInput: false,
      recognizeSentences: [],
      question: '',
      manager: null,
      hasPermission: false,
      cred: {}
    }
  },


  methods: {
    switchInputMode() {
      this.inputMode = this.inputMode === 'voice' ? 'text' : 'voice'
    },

    // 提问
    ask() {
      if (!this.loading) {
        this.$emit('ask', this.question)
        this.question = ''
      }
    },


    async startVoiceInput() {
      if (this.loading) {
        return
      }
      if (!this.hasPermission) {
        const hasPermission = await hasRecordPermission()
        if (!hasPermission) {
          await requestRecordPermission()
          this.hasPermission = true
          return
        }
      }

      if (this.manager) {
        this.manager.destroyStream()
      }
      if (!this.cred.token) {
        this.cred = await tencentV2Api.tempToken()
      }
      const cred = this.cred

      const params = {
        "secretkey": cred.tmpSecretKey,
        "token": cred.token,
        "secretid": cred.tmpSecretId,
        appid: 1324394914,
        // 临时密钥参数，非必填
        // 实时识别接口参数
        engine_model_type: '16k_zh', // 因为内置WebRecorder采样16k的数据，所以参数 engineModelType 需要选择16k的引擎，为 '16k_zh'
      }

      this.manager = new WebAudioSpeechRecognizer(params, false)
      this.recognizeSentences = []

      // 开始识别
      this.manager.OnRecognitionStart = (res) => {
        this.voiceInputting = true
      }

      // 一句话开始
      this.manager.OnSentenceBegin = (res) => {
        this.recognizeSentences.push('')
      }
// 识别变化时
      this.manager.OnRecognitionResultChange = (res) => {
        if (this.recognizeSentences && this.recognizeSentences.length) {
          this.recognizeSentences[this.recognizeSentences.length - 1] = res.result.voice_text_str
        }
        this.$forceUpdate()
      }
// 一句话结束
      this.manager.OnSentenceEnd = (res) => {
        if (this.recognizeSentences && this.recognizeSentences.length) {
          this.recognizeSentences[this.recognizeSentences.length - 1] = res.result.voice_text_str
        }
        this.$forceUpdate()
      }
// 识别结束
      this.manager.OnRecognitionComplete = (res) => {
        this.voiceInputting = false
        if (this.recognizeSentences && this.recognizeSentences.length) {
          let last = this.recognizeSentences[this.recognizeSentences.length - 1]
          if (last.endsWith('。')) {
            last = last.slice(0, -1)
            this.recognizeSentences[this.recognizeSentences.length - 1] = last
          }
        }
        if (this.cancelVoiceInput) {
          return
        }

        const result = this.recognizeSentences.join('')
        if (result && result.length) {
          this.question = result
          // this.switchInputMode()
          this.ask()
        }
      }
// 识别错误
      this.manager.OnError = (res) => {
        console.log('识别失败', res)
      }

      this.manager.start()
    },

    stopVoiceInput(e) {
      if (e.changedTouches && e.changedTouches.length) {
        const clientY = e.changedTouches[0].clientY
        const rect = this.$refs.inputMain.getBoundingClientRect()
        if (clientY < rect - 10) {
          this.cancelVoiceInput = true
        }
      }
      if (this.manager) {
        this.manager.stop()
        this.manager.destroyStream()
      }
    },


    onContextMenu(e) {
      e.preventDefault();
    }

  }
}
</script>

<style lang="less" scoped>
@keyframes backgroundFade {
  0% {
    background-color: #333; /* 深灰色 */
  }
  50% {
    background-color: #ccc; /* 浅灰色 */
  }
  100% {
    background-color: #333; /* 深灰色 */
  }
}

.ai-search-input-container {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  width: 319px;
  height: calc(319px * 101 / 819);
  border-radius: 500px;
  background: linear-gradient(to right, #ee4a50, #ff4906);
  padding: 2px; /* 用于创建渐变边框的间隙 */
  z-index: 100;
  transition: height .1s;
  box-shadow: #ccc 0 5px 20px;
  bottom: 62px;
}

.ai-search-input-container .ai-search-input {
  width: 100%;
  height: 100%;
  border-radius: 50px;
  background: linear-gradient(to right, #f7dcdd, #fae4d7);
  box-sizing: border-box;
  transition: height .1s;
}

.ai-search-input-main {
  position: relative;
  align-items: center;
  display: flex;
  padding: 0 8px;
  width: 319px;
  height: calc(319px * 101 / 819);
}

.ai-search-input-container.voice-inputting {
  height: calc(319px * 291 / 819);
  border-radius: 21px;
}

.ai-search-input-container.voice-inputting .ai-search-input {
  border-radius: 20px;
  height: calc(319px * 291 / 819);
}

.voice-input-area {
  width: 319px;
  height: calc(319px * 180 / 819);
}

.ai-search-input-container .ai-wave-icon {
  position: absolute;
  width: 45px;
  height: 37px;
  right: 24px;
  top: -30px;
}

.ai-search-input-container .limit-badge {
  position: absolute;
  left: 2px;
  top: -22px;
  width: 70px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  color: rgb(80, 80, 80);
  font-size: 10px;
}

.ai-search-input-container .limit-badge img {
  height: 12px;
  width: 12px;
  margin-right: 4px;
}

.ai-search-input-container .limit-badge::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  border-radius: 100px;
  opacity: 0.1;
  background: rgba(21, 29, 54, 1);
  z-index: -1;
}

.ai-search-input .left-icon {
  height: 25px;
  width: 26px;
  flex-shrink: 0;
  user-select: none;
}

.ai-search-input .ai-input-text {
  margin: 0 10px;
  border: none;
  outline: none;
  font-size: 14px;
  background: transparent;
  color: #e1494e;
  flex-shrink: 0;
  flex-grow: 1;
  height: 100%;
  width: 235px;

  &::placeholder {
    color: #e1494e;
  }
}

.ai-search-input .ai-input-voice {
  color: #e1494e;
  flex-shrink: 0;
  position: absolute;
  left: 40px;
  top: 0;
  right: 40px;
  bottom: 0;
  font-weight: bolder;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
}

.ai-chat-placeholder {
  color: #e1494e;
}

.ai-search-input .confirm-icon {
  height: 25px;
  width: 25px;
  flex-shrink: 0;
}

.ai-search-input-container .voice-recognize {
  opacity: 0.5;
  color: rgba(0, 0, 0, 1);
  font-size: 14px;
  padding: 14px 16px;
}

.ai-search-input-container .voice-cancel-tip {
  font-size: 12px;
  opacity: 0.3;
  color: rgba(0, 0, 0, 1);
  text-align: center;
  height: 15px;
  margin-top: 14px;
}

.close-btn {
  position: absolute;
  right: 6px;
  top: -22px;
  z-index: 1;
  height: 20px;
  width: 20px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 10px;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background: #ccc;
    border-radius: 100px;
  }
}

.stop-btn {
  border-radius: 100px;
  border: 2px solid #d43030;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 22px;
  width: 22px;
  transform: translateX(-4px);
  flex-shrink: 0;

  &::after {
    content: '';
    height: 12px;
    width: 12px;
    border-radius: 4px;
    background: #444;
    animation-name: backgroundFade;
    animation-duration: 2s; /* 动画持续时间 */
    animation-iteration-count: infinite; /* 动画无限循环 */
    animation-timing-function: ease-in-out; /* 动画速度曲线 */
    cursor: pointer;
  }
}

</style>
